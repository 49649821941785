import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import BackgroundImage from "gatsby-background-image";

function ImagePoster() {
	const {allFile}  = useStaticQuery(
		graphql`
				query {
					allFile(filter: {relativeDirectory: {eq: "poster"}}) {
						edges {
						  node {
							id,
							name,
							childImageSharp {
							  fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							  }
							}
						  }
						}
					  }
				}
				`
	);
	const get_image_label = name_arr => {
		return `${toProperCase(name_arr[0])}, ${name_arr[1]}`
	}
	const toProperCase = word => {
		return word.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
	}
	return (<div className="flex flex-wrap">
		{/* {console.log(allFile)}  */}
		{allFile.edges.map(element => {
			return (
			
			<BackgroundImage className="front-image-div" fluid={element.node.childImageSharp.fluid} key={element.node.id}>
				
				<div className="image-shadow">
				<div className="photo-label">{get_image_label(element.node.name.split("_"))}</div>
				</div>

			</BackgroundImage>
			
			)
		})}
		</div>)
	
}

export default ImagePoster;
