import React from 'react';
import { FaGithub } from 'react-icons/fa';

const Social = () => {
	// console.log(GithubLogo)
	return (
		<a href="http://github.com/aleneapen" target="_blank" rel="noopener noreferrer">
			<FaGithub className="github-logo" />
		</a>
	);
};

export default Social;
