import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const Navigation = () => {
	const { allFile } = useStaticQuery(graphql`
		{
			allFile(filter: { sourceInstanceName: { eq: "nav_pages" } }) {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`);
	// console.log(allFile.edges)
	const convertPageToLink = (name) => {
		return name === 'index' ? '/' : `/${name}/`;
	};
	const convertPageToName = (name) => {
		return name === 'index' ? 'HOME' : name.toUpperCase();
	};
	let menuPages = [ 'index', 'posts' ];
	return (
		<div className="navigation">
			<ul>
				{allFile.edges.map((page) => {
					if (page.node.name === 'tags') {
						return null;
					}
					if (page.node.name === 'index') {
						menuPages[0] = page;
					}
					if (page.node.name === 'posts') {
						menuPages[1] = page;
					}
					return null;
				})}
				{menuPages.map((page) => {
					return (
						<li key={page.node.id}>
							<Link activeClassName="active-link" to={convertPageToLink(page.node.name)}>
								{convertPageToName(page.node.name)}
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Navigation;
