import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Navigation from './navigation';
import Social from './social';

const SideBar = (props) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`);
	// console.log(props)
	return (
		<div className="site-info-bar overflow-hidden">
			<h1 className="text-5xl">
				<Link to="/" className="title-link">
					{data.site.siteMetadata.title}
				</Link>
			</h1>
			<Navigation />
			<Social />
		</div>
	);
};

export default SideBar;
