import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImagePoster from "../components/image_poster"
import SideBar from "../components/sidebar"

const IndexPage = (props) => (
  <Layout>
    
    <SEO title="Home"/>
    <SideBar path={props.path} />
    <div className="content"><ImagePoster/></div>
  </Layout>
)

export default IndexPage
